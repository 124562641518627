<template>
  <div class="Menu">
    <v-dialog
      v-model="showIntro"
      persistent
      fullscreen
      transition="fade-transition"
      overlay-color="white"
    >
     <template v-if="renderVideos">
        <video
          :src="'https://srtanakacms.actstudio.xyz/storage/uploads'+videosSingleton.video2.path"
          muted
          autoplay
          style="height: 100vh; width: 100%; object-fit: cover"
          playsinline
          loop
          class="d-none d-sm-block"
        ></video>
       
        <video
          :src="'https://srtanakacms.actstudio.xyz/storage/uploads'+videosSingleton.video2_mobile.path"
          muted
          autoplay
          style="height: 100vh; width: 100%; object-fit: cover"
          playsinline
          loop
          class="d-sm-none"
        ></video>
     </template>
    </v-dialog>

   <template v-if="showIntro == false">
      <v-container fluid class="py-0">
        <!-- contenido tablet v hacia arriba -->
        <v-row class="d-none d-sm-flex">
          <v-col cols="12" class="">
            <v-row
              v-for="(category, c) in categories"
              v-bind:key="c"
              justify="center"
              class="d-none d-sm-flex background-black-menu"
              no-gutters
            >
              <v-col
                cols="12"
                class="padding-subcategories-titles background-white"
              >
                <span class="subtitles-app-and-buttons d-block my-3">
                  {{ category.name }}
                </span>
              </v-col>
     
              <v-col cols="12">
                <v-row no-gutters>
                  <!-- <template v-for="(subcategory, sr) in category.subcategory_linked">
                   <v-img src="@/assets/icons/accordion-icon.svg" class="accordion-icon" v-bind:key="sr"></v-img>
                  </template> -->
     
                  <v-col
                    v-for="(subcategory, s) in category.subcategory_linked"
                    v-bind:key="s"
                    v-bind:sm="12 / subcategory.columns"
                    v-bind:class="{
                      inverse_colors_borders: c > 1,
                      'no-border-left': s % 2 == 1 && subcategory.columns != 1,
                    }"
                    class="padding-subcategories borders"
                    cols="12"
                  >
                    <span
                      class="d-block titles-menu position-relative"
                      style="line-height: 1.2"
                    >
                      {{ subcategory.name }}
                    </span>
                    <span
                      class="d-block titles-kanji"
                      v-bind:class="{ inverse: c > 1 }"
                    >
                      {{ subcategory.name_kanji }}
                    </span>
     
                    <!-- <div class="dish-columns">
                      <div
                        v-for="(dish, d) in subcategory.foodDishes_linked"
                        v-bind:key="d"
                        v-bind:class="{'column-1': dish.columns == 1, 'column-2': dish.columns == 2, 'column-3': dish.columns == 3, 'column-4': dish.columns == 4}"
                      >
                        <span class="d-block subtitles-menu">
                          {{ dish.name }}
                        </span>
                        <span>
                          {{ dish.dish_1_text }}
                        </span>
                      </div>
                    </div> -->
     
                    <v-row justify="space-between">
                      <v-col
                        v-for="(dish, d) in subcategory.foodDishes_linked"
                        v-bind:key="d"
                        v-bind:sm="12 / dish.columns"
                        cols="12"
                      >
                        <span class="d-block subtitles-menu">
                          {{ dish.name }}
                        </span>
     
                        <div>
                          <span class="mercuryTextG1">
                            {{ dish.dish_1_text }}
                          </span>
                          <span class="mercuryTextG1 bold">{{
                            dish.dish_1_quantity
                          }}</span>
                        </div>
     
                        <template v-if="dish.dish_2_text != ''">
                          <div>
                            <span class="mercuryTextG1">
                              {{ dish.dish_2_text }}
                            </span>
                            <span class="mercuryTextG1 bold">{{
                              dish.dish_2_quantity
                            }}</span>
                          </div>
                        </template>
     
                        <template v-if="dish.dish_3_text != ''">
                          <div>
                            <span class="mercuryTextG1">
                              {{ dish.dish_3_text }}
                            </span>
                            <span class="mercuryTextG1 bold">{{
                              dish.dish_3_quantity
                            }}</span>
                          </div>
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- /contenido tablet v hacia arriba -->
     
        <!-- contenido solo mobile -->
        <template v-if="render">
          <v-row class="d-sm-none">
            <v-col cols="12" class="pa-sm-0">
              <v-row
                v-for="(category, c) in categories"
                v-bind:key="c"
                justify="center"
                class="background-black-menu"
                no-gutters
              >
                <v-col
                  cols="12"
                  class="padding-subcategories-titles background-white"
                >
                  <span class="subtitles-app-and-buttons d-block my-6">
                    {{ category.name }}
                  </span>
                </v-col>
     
                <v-col cols="12">
                  <v-row no-gutters>
                    <!-- <template v-for="(subcategory, sr) in category.subcategory_linked">
                     <v-img src="@/assets/icons/accordion-icon.svg" class="accordion-icon" v-bind:key="sr"></v-img>
                    </template> -->
     
                    <v-col cols="12">
                      <v-expansion-panels
                        light
                        tile
                        accordion
                        v-model="subcategoriesPanel[c]"
                        v-bind:multiple="c == 0"
                      >
                        <v-expansion-panel
                          v-for="(subcategory, s) in category.subcategory_linked"
                          v-bind:key="s"
                          v-bind:class="[
                            s >= 0 ? 'panel-target-' + subcategory._id : '',
                            {
                              'inverse_colors_borders background-black': c > 1,
                            },
                          ]"
                          v-bind:active-class="
                            c == 0 && s <= 1
                              ? 'panel-subcategory-normal'
                              : 'panel-subcategory'
                          "
                          v-bind:readonly="c == 0 && s <= 1"
                          v-bind:id="'subcategory-' + subcategory._id"
                          @click="goToTarget(subcategory._id)"
                          class="padding-subcategories borders panel-target"
                        >
                          <v-expansion-panel-header
                            class="pa-0 d-block position-relative"
                            expand-icon=""
                          >
                            <span
                              class="d-block titles-menu position-relative"
                              style="line-height: 1.2"
                            >
                              {{ subcategory.name }}
                            </span>
                            <span
                              class="d-block titles-kanji"
                              v-bind:class="{ inverse: c > 1 }"
                            >
                              {{ subcategory.name_kanji }}
                            </span>
     
                            <template>
                              <img
                                class="accordion-icon"
                                v-bind:src="
                                  c > 1 ? arrowDownWhite : arrowDownBlack
                                "
                                v-show="c != 0 || s >= 2"
                              />
                            </template>
                          </v-expansion-panel-header>
     
                          <v-expansion-panel-content>
                            <v-row justify="space-between">
                              <v-col
                                v-for="(dish, d) in subcategory.foodDishes_linked"
                                v-bind:key="d"
                                v-bind:sm="12 / dish.columns"
                                cols="12"
                              >
                                <span class="d-block subtitles-menu">
                                  {{ dish.name }}
                                </span>
     
                                <div>
                                  <span class="mercuryTextG1">
                                    {{ dish.dish_1_text }}
                                  </span>
                                  <span class="mercuryTextG1 bold">{{
                                    dish.dish_1_quantity
                                  }}</span>
                                </div>
     
                                <template v-if="dish.dish_2_text != ''">
                                  <div>
                                    <span class="mercuryTextG1">
                                      {{ dish.dish_2_text }}
                                    </span>
                                    <span class="mercuryTextG1 bold">{{
                                      dish.dish_2_quantity
                                    }}</span>
                                  </div>
                                </template>
     
                                <template v-if="dish.dish_3_text != ''">
                                  <div>
                                    <span class="mercuryTextG1">
                                      {{ dish.dish_3_text }}
                                    </span>
                                    <span class="mercuryTextG1 bold">{{
                                      dish.dish_3_quantity
                                    }}</span>
                                  </div>
                                </template>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <!-- /contenido solo mobile -->
     
        <v-row class="padding-subcategories">
          <v-col class="pa-3 pa-md-0">
            <p class="mercuryTextG1 mb-0 text-disclaimer">
              El gramaje corresponde al total del platillo en crudo.
              <br />
              El consumo de proteína cruda es bajo responsabilidad del comensal .
              <br />
              Debido a que manejamos productos frescos, algunos de nuestro
              platillos
              <br />
              Podrían no estar disponibles.
            </p>
          </v-col>
        </v-row>
      </v-container>
   </template>
  </div>
</template>

<script>
import arrowDownBlack from "@/assets/icons/accordion-icon.svg";
import arrowDownWhite from "@/assets/icons/accordion-icon-white.svg";

export default {
  data() {
    return {
      render: false,
      categories: [],
      showIntro: true,
      subcategoriesPanel: [0, 1],
      arrowDownBlack,
      arrowDownWhite,
      videosSingleton: {},
      renderVideos: false
    };
  },
  beforeCreate () {
    setTimeout(() => {
      this.showIntro = false;
    }, 7000);

    fetch(
      "https://www.srtanakacms.actstudio.xyz/api/singletons/get/videos?token=1635b46f0568a19ed800dc70231e84",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
          filter: {
            featured: true,
          },
        }),
      }
    )
    .then((data) => data.json())
    .then((data) => (this.videosSingleton = data))
    .then((data) => (this.renderVideos = true))
    .catch((error) => (console.log(error.message)));
  },
  mounted() {
    var aux = this;

    //fetch para el menú
    fetch(
      "https://www.srtanakacms.actstudio.xyz/api/collections/get/categories?token=1635b46f0568a19ed800dc70231e84",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 3,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.categories = res.entries))
      .then((res) => (this.render = true))
      .then((res) => {
        var array = [];

        for (let index = 0; index < aux.categories.length; index++) {
          if (index == 0) {
            array.push([0, 1]);
          } else {
            array.push(-1);
          }
        }

        aux.subcategoriesPanel = array;
      })
      .catch((error) => console.log(error.message));

    //evento que emite para cambiar los colores
    this.$emit("update", 1);
  },
  methods: {
    goToTarget: function (id) {
      var selector = document.getElementById("subcategory-" + id);

      if (selector) {
        var aux = this;
        setTimeout(()=>{

          aux.$vuetify.goTo(selector);
        }, 500);
      }
    },
  },
};
</script>